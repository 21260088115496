import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { ErrorBoundary } from '@sentry/react';
import { Button, ButtonProps, Modal } from 'antd';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Suspense } from 'react';
import styled from 'styled-components';

import { CdClose } from '../Icons';
import CdrPageLoader from '../CdrPageLoader';
import { CdErrorPage } from '../cd-error-page/CdErrorPage';
import getTextCatalog from '../../../services/I18nService';

// Difference between Modal and Pop-up: https://ux.stackexchange.com/questions/94423/should-clicking-outside-an-important-modal-close-it

const CustomModal = styled(Modal)<{
  $maxBodyHeight: number;
  $noPadding: boolean;
}>`
  ${(props) => props.$maxBodyHeight && ' top: 20px'};

  .ant-modal-body {
    ${(props) =>
      props.$maxBodyHeight && `max-height: ${props.$maxBodyHeight}vh`};
    ${(props) => props.$maxBodyHeight && 'overflow-y: scroll'};
    ${(props) => props.$noPadding && 'padding: 0px'};
  }
`;

export enum ModalType {
  MODAL = 'modal',
  POPUP = 'pop-up',
}

// Ant Docs here: https://ant.design/components/modal#api
export interface InnerModalProps<Result = unknown> {
  modalType: ModalType;
  title?: string | React.ReactNode;
  width?: number | string;
  okButtonProps?: ButtonProps;
  okText?: string | React.ReactNode;
  cancelText?: string;
  hideOk?: boolean;
  onOk?: () => Promise<Result> | Result;
  onCancel?: () => unknown;
  maxBodyHeight?: number; // it should be based on 'vh'
  noPadding?: boolean;
  handleAlternativeButtonClick?: () => unknown;
  alternativeButtonProps?: ButtonProps;
  alternativeButtonText?: string | React.ReactNode;
}

export type SetModalPropsType = React.Dispatch<
  React.SetStateAction<InnerModalProps<unknown>>
>;

interface InnerModalType {
  setModalProps: SetModalPropsType;
  loading?: boolean;
}

// Makes sure that if the <T> is NOT defined when using createCdModal, no props are required.
type OptionalIfVoid<T = unknown> = void extends T ? void : T;

// Insert a Functional Component into this function, and it will return a show function which takes T as parameters.
// The createCdModal function should align with https://app.shortcut.com/churchdesk/write/IkRvYyI6I3V1aWQgIjY2NmFkNTkyLTU4NzUtNDYyYS1hYmRmLWE2MGEyMTA0NTI3OCI=
export const createCdModal = <T, Result = unknown>(
  InnerModal: FunctionComponent<T & InnerModalType>
) => {
  const createdModal = NiceModal.create((callingProps: T) => {
    const modal = useModal();
    const [modalProps, _setModalProps] = useState<InnerModalProps<Result>>({
      modalType: ModalType.MODAL,
      title: '',
      width: 800,
    });

    const setModalProps = useCallback((newValues) => {
      if (typeof newValues === 'function') {
        _setModalProps((prevValues) => ({
          ...prevValues,
          ...newValues(prevValues),
        }));
      } else {
        _setModalProps((prevValues) => ({ ...prevValues, ...newValues }));
      }
    }, []);

    const [disable, setDisable] = useState<boolean>(false);
    const [crashed, setCrashed] = useState<boolean>(false);

    const onOk = useCallback(async () => {
      try {
        setDisable(true);
        const result = await modalProps.onOk?.();
        modal.resolve({ result, resolved: true });
        await modal.hide();
      } catch (error) {
        setDisable(false);
      }
    }, [modal, modalProps]);

    const onCancel = useCallback(async () => {
      try {
        setDisable(true);
        await modalProps.onCancel?.();
        modal.resolve({ resolved: false });
        modal.hide();
      } catch (error) {
        setDisable(false);
      }
    }, [modal, modalProps]);

    const habdleExtraElementClick = useCallback(async () => {
      try {
        setDisable(true);
        modalProps.handleAlternativeButtonClick &&
          (await modalProps.handleAlternativeButtonClick());
        modal.resolve(false);
        modal.hide();
      } catch (error) {
        setDisable(false);
      }
    }, [modal, modalProps]);

    // Ok and cancel buttons
    const footer = [
      <Button key="cancel" onClick={onCancel}>
        {modalProps.cancelText || getTextCatalog.getString('Cancel')}
      </Button>,
      modalProps.alternativeButtonText && (
        <Button
          {...{
            ...modalProps.alternativeButtonProps,
          }}
          disabled={
            disable || crashed || modalProps.alternativeButtonProps?.disabled
          }
          loading={disable}
          key="extra"
          onClick={habdleExtraElementClick}
        >
          {modalProps.alternativeButtonText}
        </Button>
      ),
      !modalProps.hideOk && (
        <Button
          {...{
            ...modalProps.okButtonProps,
            type: modalProps.okButtonProps?.type || 'primary',
          }}
          disabled={disable || crashed || modalProps.okButtonProps?.disabled}
          loading={disable}
          key="ok"
          onClick={onOk}
        >
          {modalProps.okText || getTextCatalog.getString('Ok')}
        </Button>
      ),
    ];

    return (
      <CustomModal
        {...antdModalV5(modal)}
        {...modalProps}
        {...{
          closable: modalProps.modalType !== ModalType.MODAL,
          maskClosable: modalProps.modalType !== ModalType.MODAL,
          keyboard: modalProps.modalType !== ModalType.MODAL,
          closeIcon: <CdClose />,
          okButtonProps: {
            ...modalProps.okButtonProps,
            disabled: disable || modalProps.okButtonProps?.disabled,
            loading: disable || modalProps.okButtonProps?.loading,
          },
          onCancel,
          footer,
        }}
        destroyOnClose
        $maxBodyHeight={modalProps.maxBodyHeight}
        $noPadding={modalProps.noPadding}
      >
        <ErrorBoundary
          onError={() => setCrashed(true)}
          fallback={<CdErrorPage />}
        >
          <Suspense fallback={<CdrPageLoader />}>
            <InnerModal
              setModalProps={setModalProps}
              closeModal={() => modal.hide()}
              loading={disable}
              {...callingProps}
            />
          </Suspense>
        </ErrorBoundary>
      </CustomModal>
    );
  });

  return async (props: OptionalIfVoid<T>) =>
    NiceModal.show<{ result: Result; resolved: boolean }, T, any>(
      createdModal,
      props ? props : {}
    );
};

import React from 'react';
import { Form, Layout } from 'antd';
import styled from 'styled-components';
import { Rule } from 'rc-field-form/lib/interface';

import Redactor from '../shared/containers/Redactor';
import MailContainer from '../shared/containers/MailContainer';

import { ContentBlock, ContentType, SORT } from './components/content-block';
import BlogSearchForm from './components/BlogSearchForm';
import ContributionsSearchForm from './components/ContributionsSearch';
import CoverImage from './components/cover-image/CoverImage';
import EventSearchForm from './components/EventSearchForm';
import Footer from './components/Footer';
import FormSearchForm from './components/FormSearchForm';
import Logo from './components/Logo';
import HeadingInputWithEmoji from './HeadingInputWithEmoji';

import { NewsletterSettings } from '@/react/people/message-editor/shared/containers/NewsletterSettings';
import { useMessage } from '@/react/people/hooks';
import getTextCatalog from '@/react/services/I18nService';
import { PrintEvent } from '@/react/calendar/models/calendar';
import {
  MessageStatisticsFilters,
  MessageType,
  PeopleMessageBlog,
  PeopleMessageContributions,
  PeopleMessageForm,
} from '@/react/people/types/message';
import { CdNotFound } from '@/react/shared/components/cd-not-found/CdNotFound';

const { Content, Sider } = Layout;
interface NewsletterEditorProps {
  messageId?: number;
  to: any[];
  statisticsFilter: MessageStatisticsFilters;
  contactIds: number[];
}

const Container = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 16px auto;

  .ant-form-item-explain-error {
    position: absolute;
    top: -16px;
    left: 38px;
  }

  .redactor-box {
    padding: 0 40px;

    .redactor-toolbar-box {
      border-bottom: 1px solid #ccc;
    }
    .redactor-layer {
      border: 0px;
      padding: 24px 0;
    }
  }
`;

const NewsletterSettingsSider = styled(Sider)`
  &&&& {
    padding: 0 16px 16px 16px;
    overflow: auto;
    height: 100%;
    .ant-form-item-control {
      flex: unset;
    }
  }
`;

const ContentDivider = styled.hr`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  margin: 0 32px 23px;
`;

const NewsletterLayout = styled(Layout)`
  &&&& {
    background-color: white;
    padding: 16px 0 0 16px;
    height: 100%;
    overflow: hidden;
  }
`;

const ContentWithRightSider = styled(Content)`
  &&&& {
    overflow: auto;
    background-color: #d4d4d5;
  }
`;

export default function NewsletterEditor({
  messageId,
  to,
  contactIds,
  statisticsFilter,
}: NewsletterEditorProps) {
  const { message, refetchMessage } = useMessage(messageId, MessageType.SIMPLE);
  const [form] = Form.useForm();
  const validateTitle: Rule = {
    type: 'string',
    max: 255,
    message: getTextCatalog.getString(
      'The message title cannot be longer than 255 characters, including spaces.'
    ),
  };

  if (!message?.id && messageId) {
    return (
      <CdNotFound
        title={getTextCatalog.getString('Not found')}
        subTitle={getTextCatalog.getString('The message could not be found.')}
      />
    );
  }

  return (
    <MailContainer
      messageId={messageId}
      to={to}
      contactIds={contactIds}
      type={MessageType.SIMPLE}
      form={form}
      statisticsFilter={statisticsFilter}
    >
      <NewsletterLayout>
        <ContentWithRightSider>
          <Container>
            <Logo />
            <Form.Item name="picture" noStyle>
              <CoverImage
                messageId={messageId}
                refetchMessage={refetchMessage}
              />
            </Form.Item>
            <Form.Item name="title" rules={[validateTitle]}>
              <HeadingInputWithEmoji
                placeholder={getTextCatalog.getString('Add your headline here')}
              />
            </Form.Item>
            <Form.Item name={['content', 'body']} noStyle>
              <Redactor imagePluginEnabled={false} />
            </Form.Item>
            <ContentDivider />
            <Form.Item name={['content', 'blogTitle']} rules={[validateTitle]}>
              <HeadingInputWithEmoji
                placeholder={getTextCatalog.getString(
                  'Add an optional headline for blogs here'
                )}
              />
            </Form.Item>
            <Form.Item name="news" noStyle>
              <ContentBlock<PeopleMessageBlog>
                sort={{
                  key: 'publishDate',
                  type: SORT.DATE,
                  sortDirection: 'ASC',
                }}
                contentType={ContentType.BLOG}
                id="blogs"
                DrawerContent={BlogSearchForm}
              />
            </Form.Item>
            <ContentDivider />
            <Form.Item
              name={['content', 'evnetsTitle']}
              rules={[validateTitle]}
            >
              <HeadingInputWithEmoji
                placeholder={getTextCatalog.getString(
                  'Add an optional headline for events here'
                )}
              />
            </Form.Item>
            <Form.Item name="events" noStyle>
              <ContentBlock<PrintEvent>
                contentType={ContentType.EVENT}
                showLocation={message.content?.newsletterEventLocation}
                showShortDescription={
                  message.content?.newsletterShowShortDescription
                }
                sort={{
                  key: 'startDate',
                  type: SORT.DATE,
                  sortDirection: 'DESC',
                }}
                formFromProp="formUrl"
                signUpText={getTextCatalog.getString('Sign up')}
                DrawerContent={EventSearchForm}
              />
            </Form.Item>
            <ContentDivider />
            <Form.Item
              name={['content', 'contributionsTitle']}
              rules={[validateTitle]}
            >
              <HeadingInputWithEmoji
                placeholder={getTextCatalog.getString(
                  'Add an optional headline for donations here'
                )}
              />
            </Form.Item>
            <Form.Item name="contributions" noStyle>
              <ContentBlock<PeopleMessageContributions>
                contentType={ContentType.CONTRIBUTION}
                imagePath={['file', 'uri']}
                formFromProp="url"
                sort={{
                  key: 'startDate',
                  type: SORT.DATE,
                  sortDirection: 'ASC',
                }}
                signUpText={getTextCatalog.getString('Donate')}
                DrawerContent={ContributionsSearchForm}
              />
            </Form.Item>
            <ContentDivider />
            <Form.Item name={['content', 'formsTitle']} rules={[validateTitle]}>
              <HeadingInputWithEmoji
                placeholder={getTextCatalog.getString(
                  'Add an optional headline for forms here'
                )}
              />
            </Form.Item>
            <Form.Item name="forms" noStyle>
              <ContentBlock<PeopleMessageForm>
                contentType={ContentType.FORM}
                sort={{ key: 'createdAt', type: SORT.DATE }}
                formFromProp="url"
                signUpText={getTextCatalog.getString('Sign up')}
                DrawerContent={FormSearchForm}
              />
            </Form.Item>
            <Footer
              name={message.organization?.name}
              address={message.organization?.address}
              email={message.organization?.organizationEmail}
            />
          </Container>
        </ContentWithRightSider>
        <NewsletterSettingsSider theme="light" width={400}>
          <NewsletterSettings />
        </NewsletterSettingsSider>
      </NewsletterLayout>
    </MailContainer>
  );
}

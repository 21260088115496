import { Form } from 'antd';
import { useCallback, useEffect } from 'react';

import getTextCatalog from '../../services/I18nService';
import { PostGroupMessageModalProps } from '../components/PostGroupMessageModal';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';
import StateServiceFactory, {
  navigate,
} from '@/react/services/StateServiceFactory';
import {
  ModalType,
  SetModalPropsType,
} from '@/react/shared/components/cd-modal-new/CdModal2';

export const usePostGroupMessageModal = ({
  message,
  setModalProps,
  group,
}: PostGroupMessageModalProps & { setModalProps: SetModalPropsType }) => {
  const [form] = Form.useForm();
  const state = StateServiceFactory();

  const onOk = useCallback(
    () =>
      form.validateFields().then(async (values) => {
        const response = await (!message
          ? mainApi.post('/messages', {
              ...values,
              groupId: group.id,
              type: 'group_message',
            })
          : mainApi.put(`/messages/${message.id}`, {
              ...values,
              id: message.id,
              groupId: group.id,
              type: 'group_message',
            }));

        if (response.ok) {
          NotificationService.notifySuccess(
            getTextCatalog.getString(
              !message
                ? 'A new message has been created.'
                : 'The message has been updated.'
            )
          );
        } else {
          NotificationService.notifyError(
            getTextCatalog.getString(
              !message
                ? 'Failed to create the message.'
                : 'Failed to update the message.'
            )
          );
        }
        navigate(state.current.name, {}, { reload: true });
      }),
    [form, group.id, message, state]
  );

  useEffect(
    () =>
      setModalProps({
        title: !message
          ? getTextCatalog.getString('New message')
          : getTextCatalog.getString('Edit message'),
        modalType: ModalType.MODAL,
        width: 600,
        onOk,
        okText: !message
          ? getTextCatalog.getString('Post message')
          : getTextCatalog.getString('Save'),
      }),
    [message, onOk, setModalProps]
  );

  return {
    form,
  };
};

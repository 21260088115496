import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import {
  OrganizationInfoByIdQuery,
  OrganizationUsersCache,
  useOrganizationSettings,
} from '../store/organization';
import cdApp from '../../config';
import { gettextCatalog } from '../../services/I18nService';

import { mainApi } from '@/react/api';
import NotificationService from '@/react/services/NotificationService';

export interface UploadLogoResponse {
  exceededPixelSize: number;
  url: string;
}
export interface AttachFileInterface
  extends Pick<
    UploadRequestOption<UploadLogoResponse>,
    'onSuccess' | 'onProgress' | 'onError' | 'file'
  > {
  fileName?: string;
}

/**
 * Hook to refresh the user list for the current organization
 * @returns An object with a `refreshUserList` function to refresh the user list
 */
export function useOrganizationRefreshUserList() {
  const refreshUserList = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(OrganizationUsersCache, (value) => value + 1);
      },
    []
  );
  return { refreshUserList };
}

/**
 * Usefull hook for the current session organization.
 */
export function useOrganization() {
  const { organizationSettings, refreshOrganizationSettings } =
    useOrganizationSettings();
  const OrganizationRefresher = useRecoilRefresher(
    OrganizationInfoByIdQuery(cdApp.organization.id)
  );

  const uploadPeopleLogo = useRecoilCallback(
    () =>
      async ({
        onSuccess,
        onProgress,
        onError,
        file,
        fileName,
      }: AttachFileInterface) => {
        const fileForm = new FormData();
        fileForm.append('file', file as any, fileName);
        try {
          const res = await mainApi.post<UploadLogoResponse>(
            `/organizations/upload?type=people`,
            fileForm,
            {
              onUploadProgress: (e) =>
                onProgress({ percent: (e.loaded / e.total) * 100, ...e }),
            }
          );
          onSuccess(res.data, undefined);
          refreshOrganizationSettings();
          OrganizationRefresher();
          return res.data;
        } catch (e) {
          onError(e);
        }
      },
    [OrganizationRefresher, refreshOrganizationSettings]
  );

  const removePeopleLogo = useRecoilCallback(
    () => async () => {
      const { ok, data } = await mainApi.post(
        `/organizations/upload?type=people&mode=reset`
      );

      if (!ok) {
        throw data;
      }

      refreshOrganizationSettings();
      OrganizationRefresher();
      return ok;
    },
    [OrganizationRefresher, refreshOrganizationSettings]
  );

  const updateSettings = useRecoilCallback(
    () => async (organizationSettings: any) => {
      organizationSettings.displayOrder &&
        localStorage.setItem(
          'churchdesk.peopleSettings.displayOrder',
          organizationSettings.displayOrder
        );
      if (!organizationSettings.newsletterColor) {
        organizationSettings.newsletterColor = undefined;
      }
      const { ok, data } = await mainApi.post<{ message: string }>(
        `church/settings`,
        organizationSettings
      );
      if (ok) {
        refreshOrganizationSettings();
        NotificationService.notifySuccess(
          gettextCatalog.getString('Your changes have been saved.')
        );
      } else {
        NotificationService.notifyError(
          gettextCatalog.getString('Failed to save changes.')
        );
      }
      return data;
    },
    [refreshOrganizationSettings]
  );

  return {
    organizationSettings,
    updateSettings,
    refreshOrganizationSettings,
    uploadPeopleLogo,
    removePeopleLogo,
  };
}

import {
  Button,
  Card,
  Space,
  Form,
  Input,
  Checkbox,
  Alert,
  Row,
  Col,
  Typography,
  InputNumber,
} from 'antd';
import React, { Suspense } from 'react';
import moment from 'moment';

import { useOtherSettings } from '../hooks/useOtherSettings';
import {
  minNumber,
  maxNumber,
  required,
} from '../../shared/antd/validations/common';
import CdrEmptyState from '../../shared/components/CdrEmptyState';
import AuthorizationService from '../../services/AuthorizationService';
import cdApp from '../../config';
import { CdMasqueradeTag } from '../../shared/components/CdMasqueradeTag';

import gettextCatalog from '@/react/services/I18nService';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import CdSelect from '@/react/shared/components/cd-select/CdSelect';
import { CdInputWithCopy } from '@/react/shared/components/cd-input-with-copy/CdInputWithCopy';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import {
  CdExclamationTriangleIcon,
  CdFacebookSquare,
  CdRecheckDomain,
  CdSave,
  CdSignOut,
  CdUsers,
} from '@/react/shared/components/Icons';

const { Text, Link } = Typography;

export const OtherSettingsPage = () => (
  <Suspense fallback={<CdrPageLoader />}>
    <OtherSettingsPageInner />
  </Suspense>
);

export const OtherSettingsPageInner = () => {
  const title = gettextCatalog.getString('Other settings');
  const subTitle = gettextCatalog.getString(
    'Customize the way ChurchDesk should work for your church'
  );

  const {
    form,
    canEdit,
    cdAppVersion,
    noteSettings,
    settingData,
    partnersData,
    onSaveHandler,
    initialFormValues,
    renewFacebookLogin,
    disconnectFacebook,
    organizationPrimaryDomains,
    organizationPrimaryDomainNoMatch,
  } = useOtherSettings();
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');

  return (
    <CdPage
      pageHeaderProps={{
        title,
        subTitle,
        extra: [
          canEdit && (
            <Button
              onClick={onSaveHandler}
              key="saveButton"
              type="primary"
              size="large"
              icon={<CdSave />}
            >
              {gettextCatalog.getString('Save')}
            </Button>
          ),
        ],
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        {canEdit ? (
          <Form form={form} initialValues={initialFormValues} layout="vertical">
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Card
                title={gettextCatalog.getString(
                  'General ChurchDesk landing page'
                )}
              >
                <Form.Item
                  extra={gettextCatalog.getString(
                    'Here your upcoming events are listed and if you have donation projects these will also be listed here.'
                  )}
                  name="cdLandingPage"
                >
                  <CdInputWithCopy disabled />
                </Form.Item>
              </Card>

              <Card title={gettextCatalog.getString('Organization name')}>
                <Form.Item
                  extra={gettextCatalog.getString(
                    'Here you can setup your organization name.'
                  )}
                  name="organizationName"
                  rules={[required()]}
                >
                  <Input
                    disabled={!settingData.fields.organizationName.canEdit}
                  />
                </Form.Item>
              </Card>

              <Card
                title={gettextCatalog.getString('Specify your primary domain')}
              >
                {(organizationPrimaryDomains.length > 1 ||
                  (organizationPrimaryDomainNoMatch &&
                    organizationPrimaryDomains.length > 0)) && (
                  <Form.Item
                    extra={gettextCatalog.getString(
                      'This link is used for forms, donations and landing pages.'
                    )}
                    name="domain"
                  >
                    <CdSelect
                      placeholder={gettextCatalog.getString('Select')}
                      options={organizationPrimaryDomains.map((domain) => ({
                        value: domain,
                        label: domain,
                      }))}
                    />
                  </Form.Item>
                )}
                {organizationPrimaryDomains.length === 0 && (
                  <Form.Item
                    extra={gettextCatalog.getString(
                      'This link is used for forms, donations and landing pages.'
                    )}
                    name="domain"
                  >
                    <Input disabled={!settingData.fields.domain.canEdit} />
                  </Form.Item>
                )}
              </Card>

              {cdApp.me.isChurchDeskEmployee && (
                <Card
                  title={
                    <Space>
                      {gettextCatalog.getString(
                        'Public events and blogs settings'
                      )}
                      <CdMasqueradeTag />
                    </Space>
                  }
                >
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="publishToMonoBlog"
                    >
                      <Checkbox style={{ fontWeight: 'normal' }}>
                        {gettextCatalog.getString(
                          'Syncronise events and blogs to your ChurchDesk Website'
                        )}
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      noStyle
                      name="monoBlogsMigrationComplete"
                    >
                      <Checkbox style={{ fontWeight: 'normal' }}>
                        {gettextCatalog.getString(
                          'Point links to ChurchDesk Website instead of landing.churchdesk.com'
                        )}
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      label={gettextCatalog.getString(
                        'Language migration errors'
                      )}
                      name="monoBlogsMigrationErrors"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Space>
                </Card>
              )}

              {settingData.fields.enableImageCredits.canEdit && (
                <Card title={gettextCatalog.getString('Files')}>
                  <Form.Item
                    label={gettextCatalog.getString('Copyright')}
                    valuePropName="checked"
                    name="requireImageCredits"
                    extra={gettextCatalog.getString(
                      'If checked, all users will be required to provide credit/copyright information when uploading images to ChurchDesk.'
                    )}
                  >
                    <Checkbox style={{ fontWeight: 'normal' }}>
                      {gettextCatalog.getString(
                        'Make it required to provide credit/copyright information'
                      )}
                    </Checkbox>
                  </Form.Item>
                </Card>
              )}

              {hasUserAdminRole &&
                (AuthorizationService.hasPackage('calendar') ||
                  AuthorizationService.hasPackage('intranet')) && (
                  <Card title={gettextCatalog.getString('Facebook')}>
                    {settingData.facebook &&
                      'expired' in settingData.facebook && (
                        <Alert
                          type="warning"
                          message={
                            <Row gutter={8} align="middle" justify="center">
                              <Col flex={1}>
                                <Space>
                                  <CdExclamationTriangleIcon
                                    type="warning"
                                    size="3x"
                                    style={{ color: '#69ccdb' }}
                                  />
                                  <div>
                                    {gettextCatalog.getString(
                                      "You've been logged out. Please login to Facebook."
                                    )}
                                  </div>
                                </Space>
                              </Col>
                              <Col>
                                <Button
                                  type="primary"
                                  icon={<CdRecheckDomain />}
                                  onClick={renewFacebookLogin}
                                >
                                  {gettextCatalog.getString('Renew login')}
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  icon={<CdSignOut />}
                                  danger
                                  onClick={disconnectFacebook}
                                >
                                  {gettextCatalog.getString('Disconnect')}
                                </Button>
                              </Col>
                            </Row>
                          }
                        />
                      )}

                    {!settingData.facebook && (
                      <Row align="middle" gutter={8} wrap={false}>
                        <Col flex={1}>
                          <Space size="middle">
                            <CdFacebookSquare size="4x" type="secondary" />
                            <Space size={0} direction="vertical">
                              <Text strong>
                                {gettextCatalog.getString(
                                  'Your organization is not linked to a Facebook page.'
                                )}
                              </Text>
                              {gettextCatalog.getString(
                                'Sharing the event on Facebook allows more people to discover it. Sharing your events also allows others to share it or invite their friends.'
                              )}
                            </Space>
                          </Space>
                        </Col>
                        <Col>
                          <Button onClick={renewFacebookLogin}>
                            {gettextCatalog.getString('Set up')}
                          </Button>
                        </Col>
                      </Row>
                    )}

                    {settingData.facebook && 'user' in settingData.facebook && (
                      <Row align="middle" gutter={8} wrap={false}>
                        <Col flex={1}>
                          <Space size="middle">
                            <CdFacebookSquare
                              size="4x"
                              style={{ color: '#3b5998' }}
                            />
                            <Space size={0} direction="vertical">
                              <Text strong>
                                <Link
                                  href={settingData.facebook.page?.link}
                                  target="blank"
                                >
                                  {settingData.facebook.page?.name}
                                </Link>
                              </Text>
                              <Text type="secondary" italic>
                                {gettextCatalog.getString(
                                  'Logged in as {{ username }}',
                                  {
                                    username: settingData.facebook.user?.name,
                                  }
                                )}
                              </Text>
                              {gettextCatalog.getString(
                                'Expires at: {{ date }}',
                                {
                                  date: moment(
                                    settingData.facebook.expiresAt
                                  ).format('LLL'),
                                }
                              )}
                            </Space>
                          </Space>
                        </Col>
                        <Col>
                          <Space>
                            <Button
                              type="primary"
                              icon={<CdRecheckDomain />}
                              onClick={renewFacebookLogin}
                            >
                              {gettextCatalog.getString('Renew login')}
                            </Button>
                            <Button
                              icon={<CdSignOut />}
                              danger
                              onClick={disconnectFacebook}
                            >
                              {gettextCatalog.getString('Disconnect')}
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    )}
                  </Card>
                )}

              {settingData.fields.taxPercentage.canEdit && (
                <Card title={gettextCatalog.getString('Tax percentage')}>
                  <Form.Item
                    name="taxPercentage"
                    label={gettextCatalog.getString('Tax percentage')}
                    rules={[required(), minNumber(0), maxNumber(100)]}
                  >
                    <InputNumber
                      addonBefore="%"
                      style={{ width: '100%' }}
                      controls={false}
                    />
                  </Form.Item>
                </Card>
              )}

              {hasUserAdminRole &&
                AuthorizationService.hasPackage('calendar') && (
                  <Card
                    title={gettextCatalog.getString('Auto-delete event data')}
                  >
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ width: '100%' }}
                    >
                      <Text type="secondary">
                        {gettextCatalog.getString(
                          'The Organisation Administrator can set a time frame for when internal note and sensitive information is deleted, after an event has taken place. This setting applies to all events in the calendar.'
                        )}
                      </Text>
                      <div>
                        <Form.Item
                          name="expirationEventInternalNote"
                          label={gettextCatalog.getString('Internal note')}
                        >
                          <CdSelect
                            placeholder={gettextCatalog.getString(
                              'Please select'
                            )}
                            options={noteSettings}
                          />
                        </Form.Item>
                        <Form.Item
                          name="expirationEventSensitiveNote"
                          label={gettextCatalog.getString(
                            'Sensitive information'
                          )}
                        >
                          <CdSelect
                            placeholder={gettextCatalog.getString(
                              'Please select'
                            )}
                            options={noteSettings}
                          />
                        </Form.Item>
                      </div>
                    </Space>
                  </Card>
                )}

              {hasUserAdminRole && partnersData.length > 0 && (
                <Card title={gettextCatalog.getString('External partner')}>
                  <Space align="start">
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <div>
                        <Text type="secondary">
                          {gettextCatalog.getString(
                            'This represents the id of the ChurchDesk customer in the partner system and allows the partner to identify which ChurchDesk customer is sharing event data.'
                          )}
                        </Text>
                        <br />
                        <Text type="secondary">
                          {gettextCatalog.getString(
                            'Please keep in mind that not all the partners are using this, so it is optional.'
                          )}
                        </Text>
                      </div>
                      <Form.Item
                        name="organizationPartnerMembershipId"
                        label={gettextCatalog.getString('Partner id')}
                        extra={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: gettextCatalog.getString(
                                'Please read more about the partner system <link>here</link>.',
                                {
                                  link: (text) =>
                                    `<a href="https://docs.churchdesk.com/partnership/" target="_blank">${text}</a>`,
                                }
                              ),
                            }}
                          ></span>
                        }
                      >
                        <Input />
                      </Form.Item>
                    </Space>
                  </Space>
                </Card>
              )}

              {cdApp.organization.locale.language === 'de' && (
                <Card title={gettextCatalog.getString('Gemeindebrief')}>
                  <Space align="start">
                    <img
                      src="/img/de/gep.png"
                      alt="gemeindebrief"
                      height="42"
                      width="42"
                    />
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <div>
                        <Text strong>
                          {gettextCatalog.getString(
                            'Add Gemeindebrief to ChurchDesk'
                          )}
                        </Text>
                        <br />
                        <Text type="secondary">
                          {gettextCatalog.getString(
                            "To access the Gemeindebrief database via ChurchDesk, please enter your Gemeindebrief customer number. You'll find your customer number on your Gemeindebrief invoice."
                          )}
                        </Text>
                      </div>
                      <Form.Item
                        name="gebCustomerId"
                        label={gettextCatalog.getString('Customer number')}
                        extra={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: gettextCatalog.getString(
                                "If you're not a subscriber of Gemeindebrief you can <link>sign up here</link>.",
                                {
                                  link: (text) =>
                                    `<a href="https://gemeindebrief-magazin.evangelisch.de/dazubuchen" target="_blank">${text}</a>`,
                                }
                              ),
                            }}
                          ></span>
                        }
                      >
                        <Input
                          placeholder={gettextCatalog.getString(
                            'Insert customer number'
                          )}
                        />
                      </Form.Item>
                    </Space>
                  </Space>
                </Card>
              )}
            </Space>
          </Form>
        ) : (
          <CdrEmptyState
            EmptyStateIcon={<CdUsers />}
            title={gettextCatalog.getString(
              'Only administrators can change these settings.'
            )}
            subtitle={gettextCatalog.getString(
              'Please contact your organization administrator if something needs to be changed.'
            )}
          />
        )}
        <Row justify="center">
          <Col>
            <Text type="secondary" style={{ textAlign: 'center' }}>
              Version: {cdAppVersion}
            </Text>
          </Col>
        </Row>
      </Space>
    </CdPage>
  );
};

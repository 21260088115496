(function () {
  'use strict';

  function EditController(
    moment,
    $window,
    $scope,
    $injector,
    $q,
    $state,
    $stateParams,
    gettextCatalog,
    toastr,
    Users,
    Jobs,
    Me,
    userData,
    Authorization,
    dateFormatsLookup,
    FeatureToggleService
  ) {
    // exposing the $state service in the $scope
    $scope.$state = $state;

    // ============================================================================
    // Fetch the data that is needed.
    // ============================================================================

    // Check if we are in the intranet. We will restrict some things in graveyard.
    $scope.isIntranet = true;

    // Different user profile url, if only graveyard.
    $scope.context = 'app';

    // Inject the Picture service only in the intranet.
    let Picture;
    if ($scope.isIntranet) {
      Picture = $injector.get('Picture');
    }

    // Fetch the user information.
    $scope.user = userData;
    $scope.userCopy = angular.copy(userData);

    $scope.isSameUser = parseInt($stateParams.id, 10) === Me.id;
    // Get the user's phone numbers.
    if (!_.isEmpty(userData.phones)) {
      $scope.user.mobile = _.find(userData.phones, { default: 1 }) || {};
      $scope.user.phone = _.find(userData.phones, { default: 0 }) || {};
    } else {
      $scope.user.mobile = {};
      $scope.user.phone = {};
    }

    // safeguarding profile package feature toggle
    // TODO: Remove once Safeguarding diocese feature is released
    FeatureToggleService.hasFeature('safeguarding_profile_dev_mode').then(
      (hasFeature) => {
        if (hasFeature) {
          $scope.isSafeguardingProfilePackage = true;
        }
      }
    );

    // Get the user's notification settings.
    $scope.notificationOptions = {
      absenceCreatedNotifcation: {
        title: gettextCatalog.getString('An absence is created'),
        id: 'absenceCreatedNotifcation',
        description: gettextCatalog.getString(
          'Get notified about new absences.'
        ),

        isEditable: true,
        isAllowed: true,
        values: {
          email: {
            value: $scope.user.absenceCreatedNotifcation.email || false,
            isAvailable: true,
          },

          push: {
            value: $scope.user.absenceCreatedNotifcation.push || false,
            isAvailable: true,
          },

          sms: {
            value: $scope.user.absenceCreatedNotifcation.sms || false,
            isAvailable: true,
          },
        },
      },

      bookingCreatedNotifcation: {
        title: gettextCatalog.getString('Event invitations'),
        id: 'bookingCreatedNotifcation',
        description: gettextCatalog.getString(
          'Get notified when you are needed for an event.'
        ),

        isEditable: true,
        isAllowed: true,
        values: {
          email: {
            value: $scope.user.bookingCreatedNotifcation.email || false,
            isAvailable: true,
          },

          push: {
            value: $scope.user.bookingCreatedNotifcation.push || false,
            isAvailable: true,
          },

          sms: {
            value: $scope.user.bookingCreatedNotifcation.sms || false,
            isAvailable: true,
          },
        },
      },

      bookingCanceledNotifcation: {
        title: gettextCatalog.getString('Cancellations for your events'),
        id: 'bookingCanceledNotifcation',
        description: gettextCatalog.getString(
          'Get notified if you are removed from an event.'
        ),

        isEditable: true,
        isAllowed: true,
        values: {
          email: {
            value: $scope.user.bookingCanceledNotifcation.email || false,
            isAvailable: true,
          },

          push: {
            value: $scope.user.bookingCanceledNotifcation.push || false,
            isAvailable: true,
          },

          sms: {
            value: $scope.user.bookingCanceledNotifcation.sms || false,
            isAvailable: true,
          },
        },
      },

      bookingUpdatedNotifcation: {
        title: gettextCatalog.getString('Updates for your events'),
        id: 'bookingUpdatedNotifcation',
        description: gettextCatalog.getString(
          'Get notified about updates to one of your events.'
        ),

        isEditable: true,
        isAllowed: true,
        values: {
          email: {
            value: $scope.user.bookingUpdatedNotifcation.email || false,
            isAvailable: true,
          },

          push: {
            value: $scope.user.bookingUpdatedNotifcation.push || false,
            isAvailable: true,
          },

          sms: {
            value: $scope.user.bookingUpdatedNotifcation.sms || false,
            isAvailable: true,
          },
        },
      },

      eventComment: {
        title: gettextCatalog.getString('New comment on event'),
        id: 'eventComment',
        description: gettextCatalog.getString(
          'Get notified about new comments made to events you are booked at.'
        ),

        isEditable: false,
        isAllowed: true,
        values: {
          email: { value: true, isDisabled: true, isAvailable: true },
          push: { isAvailable: false },
          sms: { isAvailable: false },
        },
      },

      rotaReminderNotification: {
        title: gettextCatalog.getString('Rota reminders'),
        id: 'rotaReminderNotification',
        description: gettextCatalog.getString(
          'Receive an email reminder 3 days ahead of your rota duty.'
        ),

        isEditable: false,
        isAllowed: true,
        values: {
          email: {
            value: $scope.user.rotaReminderNotification.email,
            isAvailable: true,
          },

          push: { isAvailable: false },
          sms: { isAvailable: false },
        },
      },

      groupMessageNotifcation: {
        title: gettextCatalog.getString('New group message or reply'),
        id: 'groupMessageNotifcation',
        description: gettextCatalog.getString(
          'Get notified about new messages and replies.'
        ),

        isEditable: true,
        isAllowed: true,
        values: {
          email: {
            value: $scope.user.groupMessageNotifcation.email || false,
            isAvailable: true,
          },

          push: {
            value: $scope.user.groupMessageNotifcation.push || false,
            isAvailable: true,
          },

          sms: {
            value: $scope.user.groupMessageNotifcation.sms || false,
            isAvailable: true,
          },
        },
      },

      attendanceTrackingNotification: {
        title: gettextCatalog.getString('Attendance reminders'),
        id: 'attendanceTrackingNotification',
        description: gettextCatalog.getString(
          'All booked users for events will receive reminders to register attendance.'
        ),

        isEditable: true,
        isAllowed: true,
        values: {
          email: { isAvailable: false },
          push: {
            value: _.get(
              $scope.user,
              'attendanceTrackingNotification.push',
              false
            ),

            isAvailable: true,
          },

          sms: { isAvailable: false },
        },
      },

      contributionTransferReceipt: {
        title: gettextCatalog.getString('Transfer receipt'),
        id: 'contributionTransferReceipt',
        description: gettextCatalog.getString(
          'Monthly transfer receipt for all received contributions'
        ),

        isEditable: true,
        isAllowed: Authorization.hasPermission('canAccessContributions'),
        values: {
          email: { value: true, isDisabled: true, isAvailable: true },
          push: { isAvailable: false },
          sms: { isAvailable: false },
        },
      },
    };

    // ============================================================================
    // General stuff.
    // ============================================================================

    // Instantiate models.
    $scope.role = {};
    $scope.job = {};

    // Set toggle default.
    $scope.opened =
      parseInt($stateParams.id, 10) !== Me.id &&
      Authorization.hasPermission('canAdministerUsers')
        ? 0
        : -1;

    // Define the sections in the edit page.
    $scope.editSections = [
      {
        templateUrl:
          '@/app/settings/users-settings/shared/partials/EditAccount.html',
        state: 'app.private.settings.users.edit.account',
        isAllowed: $scope.isSameUser,
      },

      {
        templateUrl:
          '@/app/settings/users-settings/shared/partials/EditBasic.html',
        state: 'app.private.settings.users.edit.basic',
        isAllowed:
          $scope.isSameUser ||
          Authorization.hasPermission('canAdministerUsers'),
      },

      {
        templateUrl:
          '@/app/settings/users-settings/shared/partials/EditContact.html',
        state: 'app.private.settings.users.edit.contact',
        isAllowed: $scope.isIntranet && $scope.isSameUser,
      },

      {
        templateUrl:
          '@/app/settings/users-settings/shared/partials/EditNotifications.html',
        state: 'app.private.settings.users.edit.notifications',
        isAllowed: $scope.isIntranet && $scope.isSameUser,
      },

      {
        templateUrl:
          '@/app/settings/users-settings/shared/partials/EditAdmin.html',
        state: 'app.private.settings.users.edit.administrator',
        isAllowed: Authorization.hasPermission('canAdministerUsers'),
      },

      {
        templateUrl:
          '@/app/settings/users-settings/shared/partials/EditGraveyard.html',
        state: 'app.private.settings.users.edit.graveyard',
        isAllowed: Authorization.hasPermission('canAdministerUsers'),
      },
    ];

    // Statuses.
    $scope.statusList = [
      { name: gettextCatalog.getString('Active'), status: 1 },
      { name: gettextCatalog.getString('Blocked'), status: 0 },
    ];

    // Graveyard contract types.
    $scope.contractTypes = [
      {
        name: gettextCatalog.getString('Yearly contract'),
        value: 'yearly_contract',
      },

      {
        name: gettextCatalog.getString('Monthly contract'),
        value: 'monthly_contract',
      },

      { name: gettextCatalog.getString('Trainee'), value: 'trainee' },
    ];

    // Datepicker for birthday.
    $scope.datepickerFormat = dateFormatsLookup.getFormat();

    // Toggle for sections.
    $scope.toggleOpen = function (index) {
      $scope.opened = index;

      if (index === -1) {
        $scope.user = $scope.userCopy;
      }
    };

    // Picture uploader. Only available in intranet.
    if ($scope.isIntranet) {
      $scope.pictureUploader = Picture(
        'users',
        $stateParams.id,
        function (file, response) {
          // Get the picture from the response.
          $scope.user.picture = response.image;
          $scope.user.pictureId = response.pictureId;
          toastr.success(
            gettextCatalog.getString('Profile picture has been updated.')
          );
        }
      );
    }

    // Watch mobile number for changes.
    $scope.$watch('user.mobile.phone', function () {
      let phoneInput = $('input[ng-model="user.mobile.phone"]');

      if (!phoneInput.length) {
        return;
      }

      $scope.user.mobile = $scope.user.mobile || {};
      $scope.user.mobile.countryId = phoneInput.intlTelInput(
        'getSelectedCountryData'
      ).iso2;
    });

    // Watch phone number for changes.
    $scope.$watch('user.phone.phone', function () {
      let phoneInput = $('input[ng-model="user.phone.phone"]');

      if (!phoneInput.length) {
        return;
      }

      $scope.user.phone = $scope.user.phone || {};
      $scope.user.phone.countryId = phoneInput.intlTelInput(
        'getSelectedCountryData'
      ).iso2;
    });

    // Reset the search input of ui-select after selecting a value.
    $scope.resetSelectInput = function ($search) {
      $search.search = '';
    };

    // ============================================================================
    // Save user.
    // ============================================================================

    // Save data to the server.
    $scope.save = function (form) {
      if (!form.$valid) {
        return;
      }

      let payload = {};
      let inputs = _.pickBy(form, function (value, key) {
        return key[0] !== '$' && !value.$pristine;
      });
      _.each(inputs, function (input, key) {
        _.set(payload, key, input.$modelValue);
      });
      // Send birthday in the correct format, if available.
      if (payload.birthday) {
        _.set(
          payload,
          'birthday',
          moment(payload.birthday).format('YYYY-MM-DD')
        );
      }
      // Send phone object in the correct format, if available.
      if (_.has(inputs, 'phones.mobile') || _.has(inputs, 'phones.phone')) {
        delete payload.phones;

        if (!_.isEmpty(inputs)) {
          payload.phones = [];
        }

        payload.phones.push({
          phone: $scope.user.mobile.phone,
          countryId: $scope.user.mobile.countryId,
          default: 1,
        });

        payload.phones.push({
          phone: $scope.user.phone.phone,
          countryId: $scope.user.phone.countryId,
          default: 0,
        });
      }

      payload.id = $scope.user.id;

      // If we edited notification settings.
      if (form.$name === 'notifications') {
        _.each($scope.notificationOptions, (section, key) => {
          if (!section.isEditable) return;
          payload[key] = _.mapValues(section.values, 'value');
        });
      }

      // Backend doesn't even persist it so only creates zod errors.
      // Should not be included in React version.
      delete payload.contributionTransferReceipt;

      new Users(payload).$save().then(
        function () {
          $state.reload();
        },
        function (error) {
          toastr.error(
            _.get(error, 'data.message') ||
              gettextCatalog.getString(
                'An error occurred, please try again. If the problem persists, please contact our support.'
              )
          );
        }
      );
    };

    $scope.cancel = function ($event) {
      $scope.isOrganizationAdministrator =
        Authorization.hasPermission('canAdministerUsers');
      $scope.user = angular.copy($scope.userCopy);
      $state.go('^');
      $event.stopPropagation();
    };

    // ============================================================================
    //  User Permissions
    // ============================================================================

    $scope.saveGraveyardSettings = function (adminForm) {
      let payload = {};
      payload.organizationId = $window.churchdeskOrganizationId;
      payload.id = $scope.user.id;

      let inputs = _.pickBy(adminForm, function (value, key) {
        return key[0] !== '$';
      });
      _.each(inputs, function (input, key) {
        _.set(payload, key, input.$modelValue);
      });

      let promises = [];
      promises.push(new Users(payload).$save());
      $q.all(promises).then(
        function () {
          $state.reload();
        },
        function (errors) {
          let errorMessage =
            _.get(errors, '[0].data.message') ||
            _.get(errors, '[1].data.message');
          if (errorMessage) toastr.error(errorMessage);
        }
      );
    };

    // ============================================================================
    //  Profile picture uploader
    // ============================================================================

    /**
     * Get the uploaded picture
     */
    $scope.getPicture = function (object, formItem) {
      formItem.$setDirty();
      $scope.user.picture = object.image;
      $scope.user.pictureId = object.pictureId;
    };

    /**
     * Delete the user's profile picture and mark the profile picture form control
     * as '$dirty' so it's ready to be saved.
     */
    $scope.deletePicture = function (formItem) {
      formItem.$setDirty();
      $scope.user.pictureId = null;
    };
  }
  EditController.$inject = [
    'moment',
    '$window',
    '$scope',
    '$injector',
    '$q',
    '$state',
    '$stateParams',
    'gettextCatalog',
    'toastr',
    'Users',
    'Jobs',
    'Me',
    'userData',
    'Authorization',
    'dateFormatsLookup',
    'FeatureToggleService',
  ];

  angular.module('cdApp.intranet').controller('EditController', EditController);
})();

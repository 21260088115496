import { Button, Card, Space, Typography } from 'antd';
import { ButtonType } from 'antd/es/button';
import React from 'react';
import styled from 'styled-components';

import gettextCatalog from '@/react/services/I18nService';
import cdApp from '@/react/config';

interface MessageInterface {
  messageTitle?: string;
  messageSubTitle?: string;
  icon?: JSX.Element;
  key: string;
}
interface CustomerCareDetailsInterface {
  callUsText?: string;
}
const StyledCard = styled(Card)`
  max-width: 700px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  align-items: first baseline;
`;
const StylesSpace = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`;
interface CdFallbackPageInterface {
  title?: string;
  subtitle?: string;
  hideButton?: boolean;
  buttonText?: string;
  buttonType?: ButtonType;
  buttonDisabled?: boolean;
  onButtonClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  buttonUrl?: string;
  messages?: MessageInterface[];
  customerCareDetails?: CustomerCareDetailsInterface;
  hasBody?: boolean;
}
export const CdFallbackPage = ({
  title,
  subtitle,
  hideButton,
  buttonText,
  buttonType,
  buttonDisabled,
  messages,
  buttonUrl,
  onButtonClick,
  customerCareDetails,
  hasBody = true,
}: CdFallbackPageInterface) => {
  const contryCode = cdApp.organization.countryIso2;
  const renderCustomercareButton = () => {
    switch (contryCode) {
      case 'de':
        return (
          <Button type="link" href="tel:030 568373604">
            030 568373604
          </Button>
        );
      case 'dk':
        return (
          <Button type="link" href="tel:+45 7070 2670">
            7070 2670
          </Button>
        );
      default:
        return (
          <Button type="link" href="tel:+44 (0) 20 3808 5097">
            +44 (0) 20 3808 5097
          </Button>
        );
    }
  };
  return (
    <StylesSpace direction="vertical">
      <Typography.Title level={1} style={{ marginBottom: '0px' }}>
        {title}
      </Typography.Title>
      <Typography.Text style={{ marginTop: '0px' }}>{subtitle}</Typography.Text>
      {hasBody && (
        <StyledCard>
          <Space direction="vertical" size="large">
            {messages?.map((message) => (
              <Space direction="vertical" key={message.key}>
                <Space
                  direction="horizontal"
                  size="middle"
                  style={{ display: 'flex', alignItems: 'flex-start' }}
                >
                  {message?.icon}
                  {message.messageTitle ? (
                    <Typography.Title level={2} style={{ marginTop: 0 }}>
                      {message?.messageTitle}
                    </Typography.Title>
                  ) : (
                    <Typography.Text>
                      {message?.messageSubTitle}
                    </Typography.Text>
                  )}
                </Space>
                {message.messageTitle && message.messageSubTitle && (
                  <Typography.Text>{message?.messageSubTitle}</Typography.Text>
                )}
              </Space>
            ))}
          </Space>
        </StyledCard>
      )}
      {customerCareDetails ? (
        <Space direction="vertical" align="center">
          <Typography.Title level={3}>
            {customerCareDetails.callUsText}
          </Typography.Title>
          {renderCustomercareButton()}
          <Typography.Text>{gettextCatalog.getString('OR')}</Typography.Text>
        </Space>
      ) : null}
      {hideButton || !buttonText ? null : (
        <Button
          type={buttonType || 'primary'}
          href={buttonUrl}
          disabled={buttonDisabled}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </StylesSpace>
  );
};

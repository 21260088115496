import { Button } from 'antd';
import React from 'react';

import { EditPersonalInformationModal } from './EditPersonalInformation';

import getTextCatalog from '@/react/services/I18nService';
import { CdEditIcon } from '@/react/shared/components/Icons';
export const EditProfileButton = ({
  contactId,
  personFields,
  personRelations,
}) => {
  const openModal = () => {
    EditPersonalInformationModal({
      title: 'Personal information',
      contactId,
      personFields,
      personRelations,
    });
  };
  return personFields.email.canEdit ? (
    <Button type="link" icon={<CdEditIcon />} onClick={openModal}>
      {getTextCatalog.getString('Edit')}
    </Button>
  ) : null;
};

import { useRecoilCallback, useRecoilValue } from 'recoil';

import { mainApi } from '../../api';
import { handleError } from '../../services/ErrorHandlingService';
import gettextCatalog from '../../services/I18nService';
import {
  AttachFileInterface,
  UploadLogoResponse,
} from '../../organization/hooks/useOrganization';

import { OrganizationUsers } from '@/react/organization/store/organization';

export const useSafeguardingFileUpload = () => {
  const safeguardingUsers = useRecoilValue(OrganizationUsers);
  const uploadFile = useRecoilCallback(
    () =>
      async ({ file, onSuccess, onProgress, onError }: AttachFileInterface) => {
        const formData = new FormData();
        formData.append('fromGallery', 'false');
        formData.append('file', file as any);
        try {
          const res = await mainApi.post<UploadLogoResponse>(
            '/safeguard/file/upload',
            formData,
            {
              onUploadProgress: (e) =>
                onProgress({ percent: (e.loaded / e.total) * 100, ...e }),
            }
          );
          if (!res.ok) {
            handleError(res);
          } else {
            onSuccess(res?.data);
          }
          return res.data;
        } catch (e) {
          onError(e);
        }
      }
  );
  const userOption = safeguardingUsers?.map((user) => ({
    label: user.name,
    value: user.id,
  }));
  const userOptionWithOtherField = [
    ...userOption,
    { label: gettextCatalog.getString('Other reviewer'), value: 'other' },
  ];

  return {
    uploadFile,
    safeguardingUsers,
    userOptions: userOptionWithOtherField,
  };
};

import { useRecoilValue, useSetRecoilState } from 'recoil';
import React, {
  useMemo,
  useCallback,
  Suspense,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Row,
  Col,
  Card,
  Tag,
} from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import gettextCatalog from '@/react/services/I18nService';
import { Resources } from '@/react/shared/services/ResourceService';
import { DisplayDateSpanSimple } from '@/react/shared/components/FormattedDate';
import {
  EventSearchParams,
  EventSearchQuery,
} from '@/react/calendar/store/events/calendar-view';
import { CategoryType } from '@/react/shared/models/category';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { MessageEventIds } from '@/react/people/store/message';
import { PrintEvent } from '@/react/calendar/models/calendar';
import { ColumnType } from '@/react/shared/components/cd-table';
import useOrganizations from '@/react/organization/hooks/useOrganizations';
import { CdSearch } from '@/react/shared/components/Icons';
import AuthorizationService from '@/react/services/AuthorizationService';
import { OrganizationById } from '@/react/organization/store/organization';
import cdApp from '@/react/config';
import { CategoryByTypeQuery } from '@/react/shared/services/CategoryService';
import {
  CdLocationSelect,
  retrieveSelectedChurchesAndResource,
} from '@/react/shared/components/cd-location-select/CdLocationSelect';
import { EventSearchParamsProps } from '@/react/calendar/types/service-types';
import { OrderType } from '@/react/shared/models/table';
import CdSimpleTable from '@/react/shared/components/cd-simple-table/CdSimpleTable';

export enum RowSelectionType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

interface EventTableProps {
  onRowsChange?: (events: PrintEvent[]) => void;
  rowSelectionType: RowSelectionType;
  selectedKeys?: PrintEvent[];
}

const FormSelect = styled(Select)`
  &&&& {
    min-width: 100px;
  }
`;

const CompactForm = styled(Form)`
  &&&& {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

const EventTable = ({
  onRowsChange,
  rowSelectionType,
  selectedKeys,
}: EventTableProps) => {
  const eventColumns: ColumnType<PrintEvent>[] = useMemo(
    () => [
      {
        title: gettextCatalog.getString('Date & time'),
        dataIndex: 'endDate',
        key: 'endDate',
        sorter: true,
        active: true,
        defaultSortOrder: 'ascend',
        orderType: OrderType.DATE,
        render: function renderDate(_endDate, event: PrintEvent) {
          return (
            <DisplayDateSpanSimple
              start={event.startDate}
              end={event.endDate}
              allDay={event.allDay}
            />
          );
        },
      },
      {
        title: gettextCatalog.getString('Title'),
        dataIndex: 'title',
        key: 'title',
        active: true,
        sorter: true,
      },
      {
        title: gettextCatalog.getString('Location'),
        dataIndex: 'locationName',
        key: 'locationName',
        sorter: true,
        active: true,
      },
      {
        title: gettextCatalog.getString('Parish'),
        dataIndex: 'churches',
        key: 'churches',
        sorter: false,
        active: true,
        render: function renderChurches(churches: any) {
          return Object.values(churches || []).map(({ name, id }) => (
            <Tag key={id}>{name}</Tag>
          ));
        },
      },
      {
        title: gettextCatalog.getString('Category'),
        dataIndex: 'eventCategories',
        key: 'eventCategories',
        sorter: false,
        active: true,
        render: function renderCategories(taxonomies: any) {
          return Object.values(taxonomies || []).map(({ title, id }) => (
            <Tag key={id}>{title}</Tag>
          ));
        },
      },
    ],
    []
  );

  const messageEventKeys = useRecoilValue(MessageEventIds);
  const isKeySelected = (event: PrintEvent) =>
    !messageEventKeys.includes(event.id);

  return (
    <CdSimpleTable<PrintEvent>
      emptyStateProps={{ title: gettextCatalog.getString('No events found') }}
      columns={eventColumns}
      recoilSource={EventSearchQuery}
      filters={[isKeySelected]}
      rowSelection={{
        type: rowSelectionType,
        onChange: (_id, events) => onRowsChange(events),
        selectedRowKeys: selectedKeys?.map((selected) => selected?.id),
      }}
      columnSelectorKey="EventSearchForm"
    />
  );
};

export default function EventSearchForm({
  form,
  rowSelectionType = RowSelectionType.CHECKBOX,
  onValuesChange,
}) {
  const datePickerFormat = gettextCatalog.getLongDateFormat();
  const hasCollaborationPackage =
    AuthorizationService.hasPackage('collaboration');
  const setSearchParams = useSetRecoilState(EventSearchParams);
  const { organizations, setSearchId, searchId, searchByName } =
    useOrganizations();

  useEffect(() => {
    setSearchParams((params) => ({
      ...params,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const currentOrganizationResources = useRecoilValue(Resources);
  const currentOrganizationCategories = useRecoilValue(
    CategoryByTypeQuery(CategoryType.event)
  );
  const { categories, resources } = useRecoilValue(OrganizationById(searchId));

  useEffect(() => {
    if (cdApp.organization.id === searchId) {
      setSelectedCategories(currentOrganizationCategories);
      setSelectedResources(currentOrganizationResources);
    } else {
      setSelectedCategories(categories);
      setSelectedResources(resources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId]);

  const selectCategoriesData = useMemo(
    () =>
      (selectedCategories || [])
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        ),
    [selectedCategories]
  );

  const onSearch = useCallback(
    ({ location, title, categories, startDate, endDate, ...restFormData }) => {
      setSearchParams((params) => {
        const { resources, churches } =
          retrieveSelectedChurchesAndResource(location);

        return {
          ...params,
          ...restFormData,
          ...{
            filters: {
              resources,
              eventsWithoutResourcesInChurchIds: churches,
              categories,
              title,
            },
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        } as EventSearchParamsProps;
      });
    },
    [setSearchParams]
  );

  return (
    <>
      <Card>
        <CompactForm
          onFinish={onSearch}
          initialValues={{
            startDate: moment(),
            endDate: moment().add(1, 'month'),
          }}
        >
          {hasCollaborationPackage && (
            <Row
              justify="space-between"
              style={{ flexGrow: 1 }}
              wrap
              gutter={8}
            >
              <Col flex="auto">
                <Form.Item
                  label={gettextCatalog.getString('Organization')}
                  style={{ marginRight: '0px' }}
                >
                  <Select<number>
                    showSearch
                    value={searchId}
                    onSearch={searchByName}
                    filterOption
                    optionFilterProp="label"
                    onChange={setSearchId}
                    options={organizations.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row
            justify="space-between"
            style={{ flexGrow: 1 }}
            wrap={false}
            gutter={8}
          >
            <Col flex="auto">
              <Form.Item name="title">
                <Input
                  placeholder={gettextCatalog.getString('Search')}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item name="categories">
                <FormSelect
                  placeholder={gettextCatalog.getString('Category')}
                  options={selectCategoriesData}
                  mode="multiple"
                  filterOption={(input, option) =>
                    (option.label as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  maxTagCount={0}
                  maxTagPlaceholder={(selectedItems) =>
                    gettextCatalog.getPlural(
                      selectedItems.length,
                      '1 category',
                      '{{ $count }} categories',
                      {
                        $count: selectedItems.length,
                      }
                    )
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item name="location">
                <CdLocationSelect
                  selectedResources={selectedResources}
                  aggregateSelection
                  showSearch={true}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="startDate">
                <DatePicker
                  placeholder={gettextCatalog.getString('Start date')}
                  format={datePickerFormat}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="endDate">
                <DatePicker
                  placeholder={gettextCatalog.getString('End date')}
                  format={datePickerFormat}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button icon={<CdSearch />} type="primary" htmlType="submit">
                  {gettextCatalog.getString('Search')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </CompactForm>
      </Card>

      <Form
        form={form}
        style={{ marginTop: '8px' }}
        onValuesChange={onValuesChange}
      >
        <Suspense fallback={<CdrPageLoader />}>
          <Form.Item
            name="events"
            trigger="onRowsChange"
            valuePropName="selectedKeys"
          >
            <EventTable rowSelectionType={rowSelectionType} />
          </Form.Item>
        </Suspense>
      </Form>
    </>
  );
}

import { Form } from 'antd';
import { useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import getTextCatalog from '../../services/I18nService';
import { ModalGroup } from '../components/AddMemberModal';

import { useOrganizationUsers } from './useOrganizationUsers';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import NotificationService from '@/react/services/NotificationService';
import {
  ModalType,
  SetModalPropsType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { showCreateUserModal } from '@/react/user/components/CreateUserModal';
import AuthorizationService from '@/react/services/AuthorizationService';
import StateServiceFactory from '@/react/services/StateServiceFactory';

export const useAddMemberModal = (
  setModalProps: SetModalPropsType,
  group: ModalGroup
) => {
  const { users } = useOrganizationUsers();
  const [form] = Form.useForm();
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');
  const modal = useModal();
  const stateService = StateServiceFactory();

  const createNewUser = async () => {
    const { resolved } = await showCreateUserModal({
      initialValues: { groups: [parseInt(group.id)] },
    });
    if (resolved) {
      if (stateService.current.name !== 'app.private.intranet.group.members') {
        stateService.go('app.private.intranet.group.members', {
          gid: group.id,
        });
      } else {
        stateService.reload();
      }
      modal.resolve();
      modal.hide();
    }
  };

  useEffect(
    () =>
      setModalProps({
        title: getTextCatalog.getString('Add member'),
        modalType: ModalType.MODAL,
        width: 500,
        okText: getTextCatalog.getString('Add to {{ groupName }}', {
          groupName: group.name,
        }),
        onOk: async () =>
          form.validateFields().then(async (values) => {
            const response = await mainApi.put(
              `/groups/${group.id}/users/${values.userId}/membership`,
              {
                isAdmin: false,
                status: 'active',
              }
            );
            if (response.ok) {
              NotificationService.notifySuccess(
                getTextCatalog.getString(
                  'The user has been added to the group.'
                )
              );
              if (
                stateService.current.name !==
                'app.private.intranet.group.members'
              ) {
                stateService.go('app.private.intranet.group.members', {
                  gid: group.id,
                });
              } else {
                stateService.reload();
              }
            } else {
              handleError(response);
            }
          }),
      }),
    [form, group.id, group.name, setModalProps, stateService]
  );

  return {
    form,
    createNewUser,
    users: users.filter((user) => user.status === 1),
    hasUserAdminRole,
  };
};

import { Button, Typography } from 'antd';
import React from 'react';
import moment from 'moment';

import gettextCatalog from '../../services/I18nService';
import { OrderType } from '../../shared/models/table';
import { fileInterface, fileUploadTypes } from '../types/SafeguardingTypes';
import { ColumnType } from '../../shared/components/cd-table';

export const FileDisplayTableColumns = (
  fileType: string,
  isEdit: boolean,
  fileDisplayTableRowDeleteHandler: (id: number) => void
) => {
  const CertificateOfConductTableColumns: ColumnType<any>[] = [
    {
      key: 'documentWhereabouts',
      title: gettextCatalog.getString('Document whereabouts'),
      dataIndex: 'documentWhereabouts',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) =>
        data === 'returned'
          ? gettextCatalog.getString('Returned')
          : gettextCatalog.getString('Destroyed'),
    },
    {
      key: 'refreshDate',
      title: gettextCatalog.getString('Date of expiration'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) =>
        data.refreshRequired
          ? moment(data.refreshDate).format('L')
          : gettextCatalog.getString('Not required'),
    },
    {
      key: 'reviewDate',
      title: gettextCatalog.getString('Date of review'),
      dataIndex: 'reviewDate',
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('L'),
    },
    {
      key: 'reviewedBy',
      title: gettextCatalog.getString('Reviewed by'),
      dataIndex: 'reviewedBy',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) => {
        if (data) {
          return data;
        } else {
          return gettextCatalog.getString('Other reviewer');
        }
      },
    },
    {
      key: 'comments',
      title: gettextCatalog.getString('Notes'),
      dataIndex: 'comments',
      render: (data) => (
        <Typography.Paragraph ellipsis={{ rows: 10 }}>
          {data}
        </Typography.Paragraph>
      ),
    },
  ];
  isEdit
    ? CertificateOfConductTableColumns.push({
        key: 'certificateOfConductId',
        title: '',
        dataIndex: 'id',
        align: 'center',
        render: (id) => (
          <Button
            size="small"
            type="default"
            danger
            onClick={() => fileDisplayTableRowDeleteHandler(id)}
          >
            {gettextCatalog.getString('Delete')}
          </Button>
        ),
      })
    : null;
  const DocumentsTableColumns: ColumnType<any>[] = [
    {
      key: 'documentType',
      title: gettextCatalog.getString('Document type'),
      dataIndex: 'documentType',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) => {
        switch (data) {
          case 'selfDisclosure':
            return gettextCatalog.getString('Self-disclosure statement');
          case 'codeOfConduct':
            return gettextCatalog.getString('Code of conduct');
          case 'combinedDeclaration':
            return gettextCatalog.getString('Combined declaration');
        }
      },
    },
    {
      key: 'refreshDate',
      title: gettextCatalog.getString('Date of expiration'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) =>
        data.refreshRequired
          ? moment(data.refreshDate).format('L')
          : gettextCatalog.getString('Not required'),
    },
    {
      key: 'documentDate',
      title: gettextCatalog.getString('Date of review'),
      dataIndex: 'reviewDate',
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('L'),
    },
    {
      key: 'documentReviewedBy',
      title: gettextCatalog.getString('Reviewed by'),
      dataIndex: 'reviewedBy',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) => {
        if (data) {
          return data;
        } else {
          return gettextCatalog.getString('Other reviewer');
        }
      },
    },
    {
      key: 'file',
      dataIndex: 'file',
      title: gettextCatalog.getString('File name'),
      render: (data) =>
        data && (
          <Button type="link" onClick={() => window.open(data.url, '_blank')}>
            {data?.title}
          </Button>
        ),
    },
  ];
  isEdit
    ? DocumentsTableColumns.push({
        key: 'documentId',
        title: '',
        align: 'center',
        dataIndex: 'id',
        render: (id) => (
          <Button
            size="small"
            type="default"
            danger
            onClick={() => fileDisplayTableRowDeleteHandler(id)}
          >
            {gettextCatalog.getString('Delete')}
          </Button>
        ),
      })
    : null;
  const CoursesTableColumns: ColumnType<any>[] = [
    {
      key: 'courseTitle',
      title: gettextCatalog.getString('Course title'),
      dataIndex: 'courseTitle',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
    },
    {
      key: 'courseType',
      title: gettextCatalog.getString('Course type'),
      dataIndex: 'courseType',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) =>
        data === 'basic'
          ? gettextCatalog.getString('Basic course')
          : gettextCatalog.getString('Extended course'),
    },
    {
      key: 'courseDate',
      title: gettextCatalog.getString('Course date'),
      dataIndex: 'courseDate',
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('L'),
    },
    {
      key: 'refreshDate',
      title: gettextCatalog.getString('Date of expiration'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) =>
        data.refreshRequired
          ? moment(data.refreshDate).format('L')
          : gettextCatalog.getString('Not required'),
    },
    {
      key: 'courseReviewDate',
      title: gettextCatalog.getString('Date of review'),
      dataIndex: 'reviewDate',
      defaultSortOrder: 'ascend',
      orderType: OrderType.DATE,
      render: (data) => moment(data).format('L'),
    },
    {
      key: 'courseReviewedBy',
      title: gettextCatalog.getString('Reviewed by'),
      dataIndex: 'reviewedBy',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (data) => {
        if (data) {
          return data;
        } else {
          return gettextCatalog.getString('Other reviewer');
        }
      },
    },
    {
      key: 'files',
      dataIndex: 'files',
      title: gettextCatalog.getString('File name'),
      render: (data) =>
        data &&
        data.map((item: fileInterface) => (
          <Button
            key={item?.title}
            type="link"
            onClick={() =>
              window.open(item.url, '_blank', 'noopener noreferrer')
            }
          >
            {item?.title}
          </Button>
        )),
    },
  ];
  isEdit
    ? CoursesTableColumns.push({
        key: 'courseId',
        title: '',
        align: 'center',
        dataIndex: 'id',
        render: (id) => (
          <Button
            size="small"
            type="default"
            danger
            onClick={() => fileDisplayTableRowDeleteHandler(id)}
          >
            {gettextCatalog.getString('Delete')}
          </Button>
        ),
      })
    : null;
  switch (fileType) {
    case fileUploadTypes.CERTIFICATE_OF_CONDUCT:
      return CertificateOfConductTableColumns;
    case fileUploadTypes.DOCUMENT:
      return DocumentsTableColumns;
    case fileUploadTypes.COURSE:
      return CoursesTableColumns;
  }
};
